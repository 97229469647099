import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    onDblclick: _cache[0] || (_cache[0] = _withModifiers($event => ($setup.handleDblClick($event, $props.object)), ["stop"])),
    onMousedown: _cache[1] || (_cache[1] = _withModifiers($event => ($setup.handleMouseDown($event, $props.object)), ["left"])),
    onMouseup: $setup.handleMouseUp,
    style: _normalizeStyle([
			$setup.postionalStyles($setup.startX, $setup.startY, $setup.width, $setup.height),
			!$setup.isFixedSize && {
				width: 'fit-content',
				height: 'fit-content',
				maxWidth:
					$setup.MainStore.page.width -
					$setup.MainStore.page.marginLeft -
					$setup.MainStore.page.marginRight -
					$setup.startX +
					'px',
			},
			$setup.style.zIndex && { zIndex: $setup.style.zIndex },
		]),
    class: _normalizeClass($setup.MainStore.getCurrentElementsId.includes($setup.id) ? 'active-elements' : 'text-hover'),
    ref: $setup.setElements($props.object, $props.index),
    key: $setup.id
  }, [
    ($setup.type == 'text')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: _normalizeStyle([
				$setup.style,
				$setup.style.backgroundColor == '' && { backgroundColor: 'transparent' },
				$setup.widthHeightStyle($setup.width, $setup.height),
				!$setup.isFixedSize && {
					width: 'fit-content',
					height: 'fit-content',
					maxWidth:
						$setup.MainStore.page.width -
						$setup.MainStore.page.marginLeft -
						$setup.MainStore.page.marginRight -
						$setup.startX +
						'px',
				},
			]),
          class: _normalizeClass(['dynamicText', $setup.classes]),
          "data-placeholder": ""
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.dynamicContent, (field, index) => {
            return (_openBlock(), _createBlock($setup["BaseDynamicTextSpanTag"], _normalizeProps(_mergeProps({
              key: `${field.parentField}${field.fieldname}`
            }, {
						field,
						labelStyle: $setup.labelStyle,
						selectedDynamicText: $setup.selectedDynamicText,
						setSelectedDynamicText: $setup.setSelectedDynamicText,
						index,
						parentClass: '',
					})), null, 16 /* FULL_PROPS */))
          }), 128 /* KEYED_FRAGMENT */))
        ], 6 /* CLASS, STYLE */))
      : _createCommentVNode("v-if", true),
    (
				$setup.MainStore.activeControl == 'mouse-pointer' &&
				$setup.MainStore.getCurrentElementsId.includes($setup.id)
			)
      ? (_openBlock(), _createBlock($setup["BaseResizeHandles"], { key: 1 }))
      : _createCommentVNode("v-if", true)
  ], 38 /* CLASS, STYLE, NEED_HYDRATION */))
}