import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.elementType && ['header', 'footer'].includes($setup.elementType))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "header-footer-overlay",
          style: _normalizeStyle([$setup.postionalStyles($setup.startX, $setup.startY, $setup.width, $setup.height), 'width: 100%']),
          onDblclick: _cache[0] || (_cache[0] = (e) => $setup.editHeaderFooter(e, $setup.elementType))
        }, [
          _createElementVNode("p", null, _toDisplayString(`Double Click to edit ${$setup.elementType}`), 1 /* TEXT */)
        ], 36 /* STYLE, NEED_HYDRATION */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      style: _normalizeStyle([
			$setup.style,
			$setup.style.backgroundColor == '' && { backgroundColor: 'transparent' },
			$setup.postionalStyles($setup.startX, $setup.startY, $setup.width, $setup.height),
			$setup.elementType && ['header', 'footer'].includes($setup.elementType) && 'width: 100%',
		]),
      class: _normalizeClass([
			'rectangle',
			{ 'active-elements': $setup.MainStore.getCurrentElementsId.includes($setup.id) },
			$setup.classes,
		]),
      ref: $setup.setElements($props.object, $props.index),
      onMousedown: _cache[1] || (_cache[1] = _withModifiers($event => ($setup.handleMouseDown($event, $props.object, $props.index)), ["left","stop"])),
      onMouseup: _cache[2] || (_cache[2] = _withModifiers($event => ($setup.handleMouseUp($event, $props.object, $props.index)), ["left"]))
    }, [
      (
				$setup.MainStore.activeControl == 'mouse-pointer' &&
				$setup.MainStore.getCurrentElementsId.includes($setup.id)
			)
        ? (_openBlock(), _createBlock($setup["BaseResizeHandles"], { key: 0 }))
        : _createCommentVNode("v-if", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.object.childrens, (object, index) => {
        return (_openBlock(), _createBlock(_resolveDynamicComponent(
					object.type == 'text'
						? $setup.isComponent[object.type][object.isDynamic ? 'dynamic' : 'static']
						: $setup.isComponent[object.type]
				), _normalizeProps(_mergeProps({
          key: object.id
        }, { object, index })), null, 16 /* FULL_PROPS */))
      }), 128 /* KEYED_FRAGMENT */))
    ], 38 /* CLASS, STYLE, NEED_HYDRATION */)
  ], 64 /* STABLE_FRAGMENT */))
}