export const pageSizes = {
	A10: [26, 37],
	A1: [594, 841],
	A0: [841, 1189],
	A3: [297, 420],
	A2: [420, 594],
	A5: [148, 210],
	A4: [210, 297],
	A7: [74, 105],
	A6: [105, 148],
	A9: [37, 52],
	A8: [52, 74],
	B10: [44, 31],
	"B1+": [1020, 720],
	B4: [353, 250],
	B5: [250, 176],
	B6: [176, 125],
	B7: [125, 88],
	B0: [1414, 1000],
	B1: [1000, 707],
	B2: [707, 500],
	B3: [500, 353],
	"B2+": [720, 520],
	B8: [88, 62],
	B9: [62, 44],
	C10: [40, 28],
	C9: [57, 40],
	C8: [81, 57],
	C3: [458, 324],
	C2: [648, 458],
	C1: [917, 648],
	C0: [1297, 917],
	C7: [114, 81],
	C6: [162, 114],
	C5: [229, 162],
	C4: [324, 229],
	Legal: [216, 356],
	"Junior Legal": [127, 203],
	Letter: [216, 279],
	Tabloid: [279, 432],
	Ledger: [432, 279],
	"ANSI C": [432, 559],
	"ANSI A (letter)": [216, 279],
	"ANSI B (ledger & tabloid)": [279, 432],
	"ANSI E": [864, 1118],
	"ANSI D": [559, 864],
	CUSTOM: ["*", "*"],
};
