import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-238b8d5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar" }
const _hoisted_2 = { class: "toolbar-section mt-3" }
const _hoisted_3 = ["title", "onClick"]
const _hoisted_4 = {
  viewBox: `0 0 24 24`,
  width: "16",
  height: "16"
}
const _hoisted_5 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.MainStore.controls, ({ id, aria_label, icon, isDisabled }, key) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: id }, [
            (!isDisabled)
              ? (_openBlock(), _createElementBlock("span", {
                  key: id,
                  title: aria_label,
                  class: _normalizeClass($setup.iconClasses(id, icon)),
                  onClick: $event => ($setup.MainStore.setActiveControl(key))
                }, [
                  (_openBlock(), _createElementBlock("svg", _hoisted_4, [
                    _createElementVNode("use", {
                      href: `#${icon}`,
                      style: _normalizeStyle([
									$setup.MainStore.activeControl == icon && `color:var(--primary)`,
								])
                    }, null, 12 /* STYLE, PROPS */, _hoisted_5)
                  ]))
                ], 10 /* CLASS, PROPS */, _hoisted_3))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _createVNode($setup["IconsUse"], {
        name: "layerPanel",
        key: "layerPanel",
        size: 44,
        padding: 14,
        color: $setup.MainStore.isLayerPanelEnabled ? 'var(--primary-color)' : 'var(--gray-800)',
        onClick: _cache[0] || (_cache[0] = $event => ($setup.MainStore.isLayerPanelEnabled = !$setup.MainStore.isLayerPanelEnabled))
      }, null, 8 /* PROPS */, ["color"])
    ]),
    ($setup.MainStore.isLayerPanelEnabled)
      ? (_openBlock(), _createBlock($setup["LayersPanel"], { key: 0 }))
      : _createCommentVNode("v-if", true)
  ]))
}