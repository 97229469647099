import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  key: 1,
  class: "fallback-image"
}
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle([
			$setup.postionalStyles($setup.startX, $setup.startY, $setup.width, $setup.height),
			$setup.style.zIndex && { zIndex: $setup.style.zIndex },
		]),
    ref: $setup.setElements($props.object, $props.index),
    class: _normalizeClass([$setup.MainStore.getCurrentElementsId.includes($setup.id) && 'active-elements']),
    onMousedown: _cache[0] || (_cache[0] = _withModifiers($event => ($setup.handleMouseDown($event, $props.object)), ["left"])),
    onDblclick: _cache[1] || (_cache[1] = _withModifiers($event => ($setup.handleDblClick($event, $props.object)), ["stop"])),
    onMouseup: _cache[2] || (_cache[2] = $event => ($setup.handleMouseUp($event, $props.object)))
  }, [
    ($setup.barcodeSvg)
      ? (_openBlock(), _createElementBlock("div", {
          style: _normalizeStyle([$setup.widthHeightStyle($setup.width, $setup.height), $setup.style]),
          class: _normalizeClass(['barcode', $setup.classes]),
          key: $setup.id,
          innerHTML: $setup.barcodeSvg
        }, null, 14 /* CLASS, STYLE, PROPS */, _hoisted_1))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            ($setup.width >= 100 || $setup.height >= 100)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Please Double click to select Barcode"))
              : _createCommentVNode("v-if", true)
          ])
        ])),
    (
				$setup.MainStore.activeControl == 'mouse-pointer' &&
				$setup.MainStore.getCurrentElementsId.includes($setup.id)
			)
      ? (_openBlock(), _createBlock($setup["BaseResizeHandles"], { key: 2 }))
      : _createCommentVNode("v-if", true)
  ], 38 /* CLASS, STYLE, NEED_HYDRATION */))
}