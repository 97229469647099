import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("svg", {
    viewBox: `0 0 24 24`,
    width: $props.size,
    height: $props.size,
    style: _normalizeStyle(`padding: ${$props.padding}px; margin-top: ${$props.margin}px; margin-bottom: ${$props.margin}px;`),
    class: _normalizeClass($props.class)
  }, [
    _createElementVNode("use", {
      href: `#${$props.name}`,
      style: _normalizeStyle(`fill: ${$props.color}; --icon-stroke:${$props.color};`)
    }, null, 12 /* STYLE, PROPS */, _hoisted_2)
  ], 14 /* CLASS, STYLE, PROPS */, _hoisted_1))
}