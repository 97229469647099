import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f6f9d9b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mb-1 text-xs font-bold uppercase text-gray-600"
}
const _hoisted_2 = {
  ref: "editor",
  class: "border border-gray-200 dark:border-zinc-800"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "editor",
    style: _normalizeStyle({
			height: $props.height,
		})
  }, [
    ($props.label)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString($props.label), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, null, 512 /* NEED_PATCH */)
  ], 4 /* STYLE */))
}