import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["textContent"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.field.isLabelled && ($props.field.icon == null || !$props.field.icon?.onlyIcon))
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "main-label",
          textContent: _toDisplayString($props.field.label)
        }, null, 8 /* PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true),
    (_openBlock(), _createElementBlock("div", {
      ref: 
			(el) => {
				$props.field.frappeControl(el, $props.field.name);
			}
		,
      key: $setup.MainStore.getCurrentElementsValues[0]?.id || '' + $props.field.name,
      class: "frappeControl"
    }))
  ], 64 /* STABLE_FRAGMENT */))
}