import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "page-btn-wrapper" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AppPreviewPdf = _resolveComponent("AppPreviewPdf")

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: "canvas",
      id: "canvas",
      ref: "canvasContainer",
      style: _normalizeStyle([$setup.MainStore.mode == 'editing' && { cursor: $setup.MainStore.cursor }])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.ElementStore.Elements, (page) => {
        return (_openBlock(), _createBlock($setup["AppPages"], {
          key: page.index,
          page: page
        }, null, 8 /* PROPS */, ["page"]))
      }), 128 /* KEYED_FRAGMENT */)),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", {
          class: "btn",
          onClick: _cache[0] || (_cache[0] = 
						async (event) => {
							await $setup.addNewPage(event);
						}
					)
        }, [
          _createVNode($setup["IconsUse"], {
            draggable: false,
            size: 18,
            name: "es-line-add",
            key: "es-line-add",
            color: "var(--invert-neutral)"
          }),
          _createTextVNode(" " + _toDisplayString($setup.MainStore.mode == "editing"
							? "Add New Page"
							: `Add New ${
									$setup.MainStore.mode.charAt(0).toUpperCase() +
									$setup.MainStore.mode.slice(1)
							  }`), 1 /* TEXT */)
        ]),
        ($setup.MainStore.mode != 'editing')
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn",
              onClick: _cache[1] || (_cache[1] = 
						async (event) => {
							await $setup.saveHeaderFooter(event, true);
						}
					)
            }, [
              _createVNode($setup["IconsUse"], {
                draggable: false,
                size: 18,
                name: "es-line-close",
                key: "es-line-close",
                color: "var(--invert-neutral)"
              }),
              _createTextVNode(" Close ")
            ]))
          : _createCommentVNode("v-if", true),
        ($setup.MainStore.mode != 'editing')
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "btn btn-primary",
              onClick: _cache[2] || (_cache[2] = 
						async (event) => {
							await $setup.saveHeaderFooter(event);
						}
					)
            }, [
              _createVNode($setup["IconsUse"], {
                draggable: false,
                size: 18,
                name: "es-line-check",
                key: "es-line-check"
              }),
              _createTextVNode(" " + _toDisplayString(`Save ${$setup.MainStore.mode.charAt(0).toUpperCase() + $setup.MainStore.mode.slice(1)}`), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      (!!$setup.MainStore.openModal)
        ? (_openBlock(), _createBlock($setup["AppWidthHeightModal"], {
            key: 0,
            openModal: $setup.MainStore.openModal
          }, null, 8 /* PROPS */, ["openModal"]))
        : _createCommentVNode("v-if", true),
      (!!$setup.MainStore.openDynamicModal || !!$setup.MainStore.openTableColumnModal?.table)
        ? (_openBlock(), _createBlock($setup["AppDynamicTextModal"], {
            key: 1,
            openDynamicModal: 
					$setup.MainStore.openDynamicModal || $setup.MainStore.openTableColumnModal.column
				,
            table: 
					$setup.MainStore.openTableColumnModal ? $setup.MainStore.openTableColumnModal.table : null
				
          }, null, 8 /* PROPS */, ["openDynamicModal", "table"]))
        : _createCommentVNode("v-if", true),
      (!!$setup.MainStore.openBarcodeModal)
        ? (_openBlock(), _createBlock($setup["AppBarcodeModal"], {
            key: 2,
            openBarcodeModal: $setup.MainStore.openBarcodeModal
          }, null, 8 /* PROPS */, ["openBarcodeModal"]))
        : _createCommentVNode("v-if", true),
      (!!$setup.MainStore.openImageModal)
        ? (_openBlock(), _createBlock($setup["AppImageModal"], {
            key: 3,
            openImageModal: $setup.MainStore.openImageModal
          }, null, 8 /* PROPS */, ["openImageModal"]))
        : _createCommentVNode("v-if", true),
      (!!$setup.MainStore.openJinjaModal)
        ? (_openBlock(), _createBlock($setup["AppUserProvidedJinjaModal"], { key: 4 }))
        : _createCommentVNode("v-if", true)
    ], 4 /* STYLE */)), [
      [$setup["vMarquee"], $setup.marqueeOptions]
    ]),
    ($setup.MainStore.mode == 'preview')
      ? (_openBlock(), _createBlock(_component_AppPreviewPdf, { key: 0 }))
      : _createCommentVNode("v-if", true)
  ]))
}