import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8bddfa60"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 2 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass({ baseSpanTag: !$props.field.is_static && $props.field.is_labelled })
  }, [
    (!$props.field.is_static && $props.field.is_labelled)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass([
				$props.parentClass,
				'label-text',
				'labelSpanTag',
				{
					'dynamic-span-hover':
						$props.parentClass == 'printTable' ? true : $setup.MainStore.activeControl == 'text',
					'dynamic-span-selected':
						$props.selectedDynamicText === $props.field && $props.field?.labelStyleEditing,
				},
			]),
          onClick: _cache[0] || (_cache[0] = $event => ($setup.selectDynamicText(true))),
          style: _normalizeStyle([$props.labelStyle, $props.field?.labelStyle]),
          innerHTML: 
				$props.field.label ||
				`{{ ${$props.field.parentField ? $props.field.parentField + '.' : ''}${$props.field.fieldname} }}`
			
        }, null, 14 /* CLASS, STYLE, PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true),
    _createElementVNode("span", {
      class: _normalizeClass([
				$props.parentClass,
				'dynamic-span',
				{
					'dynamic-span-hover':
						$props.parentClass == 'printTable' ? true : $setup.MainStore.activeControl == 'text',
					'dynamic-span-selected':
						$props.selectedDynamicText === $props.field && !$props.field.labelStyleEditing,
				},
				{ valueSpanTag: !$props.field.is_static && $props.field.is_labelled },
				$setup.getPageClass($props.field),
			]),
      onClick: _cache[1] || (_cache[1] = $event => ($setup.selectDynamicText())),
      style: _normalizeStyle([$props.field?.style]),
      innerHTML: $setup.parsedValue
    }, null, 14 /* CLASS, STYLE, PROPS */, _hoisted_2),
    ($props.field.suffix)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass([
				$props.parentClass,
				'dynamic-span',
				{
					'dynamic-span-hover':
						$props.parentClass == 'printTable' ? true : $setup.MainStore.activeControl == 'text',
					'dynamic-span-selected':
						$props.selectedDynamicText === $props.field && !$props.field.labelStyleEditing,
				},
			]),
          onClick: _cache[2] || (_cache[2] = $event => ($setup.selectDynamicText())),
          style: _normalizeStyle([$props.field?.style]),
          innerHTML: $props.field.suffix
        }, null, 14 /* CLASS, STYLE, PROPS */, _hoisted_3))
      : _createCommentVNode("v-if", true),
    ($props.field.nextLine)
      ? (_openBlock(), _createElementBlock("br", _hoisted_4))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}