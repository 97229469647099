import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, createBlock as _createBlock, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, (column) => {
    return (_openBlock(), _createElementBlock("td", {
      key: column.fieldname,
      style: _normalizeStyle([$props.style, $props.altStyle && $props.row.idx % 2 == 0 && $props.altStyle, column.style]),
      onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($props.setSelectedDynamicText(null)), ["self"]))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(column?.dynamicContent, (field) => {
        return (_openBlock(), _createBlock($setup["BaseDynamicTextSpanTag"], _normalizeProps(_mergeProps({
          key: `${field?.parentField}${field?.fieldname}`
        }, {
					field,
					labelStyle: $props.labelStyle,
					index: $props.row.idx,
					selectedDynamicText: $props.selectedDynamicText,
					setSelectedDynamicText: $props.setSelectedDynamicText,
					parentClass: 'printTable',
					table: $props.table,
				})), null, 16 /* FULL_PROPS */))
      }), 128 /* KEYED_FRAGMENT */))
    ], 4 /* STYLE */))
  }), 128 /* KEYED_FRAGMENT */))
}